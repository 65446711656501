<template>
  <p class="mb-3 text-sm">
    Door verder te gaan bevestig je dat je het
    <a
      href="https://www.eo.nl/privacy"
      target="_blank"
    >
      privacy statement
    </a>
    en de
    <a
      href="https://www.eo.nl/algemene-voorwaarden"
      target="_blank"
    >
      algemene voorwaarden
    </a>
    van de EO hebt gelezen en begrepen.
  </p>
</template>
