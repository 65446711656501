<script setup lang="ts">
import type { ContentSalesforceProduct } from '@eo/graphql-types'
import { useOrderStore } from '@/stores/orderStore'
import { useDonationStore } from '@/stores/donationStore'

defineProps<{
  basePrice: number
  allowMultiple: boolean
  buttonColor: string
}>()

const orderStore = useOrderStore()
const donationStore = useDonationStore()

const removeGift = () => {
  donationStore.setAmount({})
  orderStore.removeProduct(donationStore.selectedPeriod.product!)
}

const isStdProduct = (product: ContentSalesforceProduct) => {
  const key = product.key
  return key?.split('|')[2] === 'STD'
}

const isGifProduct = (product: ContentSalesforceProduct) =>
  product.key?.startsWith('GIF')

const isMicrodonationProduct = (product: ContentSalesforceProduct) =>
  product.key?.split('|')[1] === 'GIF_MICRO'

const { getPriceForProduct } = usePriceUtils()
</script>
<template>
  <div
    class="mt-5 w-full max-w-[450px] rounded-2xl border-gray-200 p-0 lg:border-2 lg:p-8"
  >
    <div class="my-4 h-0.5 bg-gray-200 lg:hidden" />
    <h4 class="mb-2 mt-8 lg:mt-0">Winkelmandje</h4>
    <div v-if="orderStore.mergedProducts?.length">
      <div
        v-for="product in orderStore.mergedProducts"
        :key="product.id"
        class="basket__item"
      >
        <div :class="`grid grid-cols-${allowMultiple ? '6' : '4'}`">
          <div
            v-if="allowMultiple ? allowMultiple : false"
            class="col-span-2 my-2"
          >
            <div
              class="flex flex-row"
              v-if="isStdProduct(product) && !isGifProduct(product)"
            >
              <EoButton
                size="xs"
                class="max-h-5 max-w-fit"
                label="Minder"
                :disabled="product.quantity === 1 || !product.quantity"
                @click="orderStore.decreaseQuantity(product)"
                :color="buttonColor"
                type="button"
              >
                <EoIcon
                  size="xs max-h-3"
                  color="white"
                  name="minus"
                />
              </EoButton>
              <p class="mx-2">{{ product.quantity ? product.quantity : 1 }}</p>
              <EoButton
                size="xs"
                class="max-h-5 max-w-fit"
                label="Meer"
                :disabled="product.quantity >= 9"
                @click="orderStore.increaseQuantity(product)"
                :color="buttonColor"
                type="button"
              >
                <EoIcon
                  class="xs max-h-3"
                  color="white"
                  name="plus"
                />
              </EoButton>
            </div>
          </div>
          <p :class="`col-span-3 my-1 ml-0 font-bold`">
            {{ product.title }}
            <span
              v-if="product.quantity > 1"
              class="ml-0 text-xs"
              >({{
                getPriceForProduct(product, false).toFixed(2).replace('.', ',')
              }}
              per stuk)
            </span>
            <button
              v-if="isMicrodonationProduct(product)"
              class="font-normal text-gray-500 hover:underline"
              type="button"
              @click="() => removeGift()"
            >
              (verwijder)
            </button>
          </p>
          <p :class="`col-span-1 my-1 text-right font-bold`">
            {{ getPriceForProduct(product).toFixed(2).replace('.', ',') }}
          </p>
        </div>
      </div>
    </div>
    <p v-else>Je winkelmandje is leeg. Selecteer een product.</p>
    <div class="my-3 hidden h-0.5 bg-gray-200 lg:block" />
    <div class="my-4 grid grid-cols-4 lg:my-0">
      <p class="font-display col-span-3 text-2xl font-bold">Totaal</p>
      <p
        class="font-display text-right text-2xl font-bold text-[var(--primary)]"
      >
        {{ orderStore.amount.replace('.', ',') }}
      </p>
    </div>
  </div>
</template>
