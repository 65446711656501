<script setup lang="ts">
import type { DynamicContentItem } from '@eo/graphql-types'
import { useOrderStore } from '@/stores/orderStore'
defineProps<{
  hero: string
  introContent: DynamicContentItem[] | null
  title: string
  basePrice: number
  allowMultiple: boolean
  buttonColor: string
}>()

const orderStore = useOrderStore()
</script>
<template>
  <div class="mb-3 mt-8 flex flex-col items-center">
    <div class="w-full max-w-[450px]">
      <EoImg
        v-if="hero"
        :src="hero"
        class="mx-auto max-w-[200px]"
      />
      <h1 class="mb-5 mt-6 px-4 md:px-0">
        {{ title }}
      </h1>
      <div
        v-if="introContent"
        class="px-4 md:px-0"
      >
        <EoPreprContent
          :items="introContent"
          class="[&>p]:my-0 [&>p]:mt-5 [&>p]:text-lg [&>ul>li]:mb-0 [&>ul>li]:text-lg [&>ul]:m-0"
        />
      </div>
      <PaymentBasket
        class="hidden lg:block"
        :merged-products="orderStore.mergedProducts"
        :base-price="basePrice"
        :allow-multiple="allowMultiple || false"
        :button-color="buttonColor"
      />
    </div>
  </div>
</template>
