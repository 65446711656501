<template>
  <div
    class="mx-auto mt-[-11px] h-0 w-0 border-b-[11px] border-l-[9px] border-r-[9px] border-b-red-400 border-l-transparent border-r-transparent"
  ></div>
  <div class="mb-5 rounded-md bg-red-400 p-2 px-3 text-white">
    <FormKit
      :classes="{
        outer: '!mb-0',
        wrapper: 'flex gap-2',
        inner: '!items-start mt-[5px]',
        messages: 'hidden',
        input: `before:content[''] peer relative !h-[15px] !w-[15px] cursor-pointer appearance-none rounded-sm border border-white transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-[15px] before:w-[15px] before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-sm before:bg-white/20 before:transition-opacity checked:border-red-50 checked:bg-red-50 [&:checked+svg]:opacity-100`,
      }"
      type="checkbox"
      label="Ik heb toestemming van mijn ouder of verzorger."
      validation="accepted"
    >
      <template #decorator>
        <EoIcon
          name="check"
          color="red-400"
          size="xs"
          class="absolute left-[1px] top-[6px] opacity-0"
        />
      </template>
    </FormKit>
  </div>
</template>
