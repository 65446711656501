<script setup lang="ts">
import type { Address } from '@eo/types'
import { getNode } from '@formkit/core'
import type { Product, SingleSale, Subscription } from '@eo/graphql-types'
import FormParentConfirm from './FormParentConfirm.vue'

const emit = defineEmits(['formSubmitted', 'membershipChanged', 'stepBack'])

const props = defineProps<{
  products: Product
  membershipRequired: boolean
  saleItem: Subscription | SingleSale
}>()

const data = reactive({
  birthDate: undefined,
})

const { isValidPhone, isValidPostalCode } = useValidationUtils()

const isBeam = computed(() => props.saleItem.theme?.communityName === 'Beam')
const BEAM_MIN_AGE = 8
const minAge = computed(() => (isBeam.value ? BEAM_MIN_AGE : 16))

const errorMessages = {
  dateOfBirthRequired:
    'Controleer je geboortedatum. Deze moet correct ingevuld worden.',
  dateOfBirthIncorrect: `Let op: De minimum leeftijd is ${minAge.value} jaar.`,
  gender: 'Geslacht is niet correct ingevuld.',
  streetName: 'Straatnaam is niet correct ingevuld.',
  cityName: 'Woonplaats is niet correct ingevuld.',
  homeNumberAddition:
    'Deze schrijfwijze van huisnummertoevoeging ondersteunen wij niet.',
  homeNumber: 'Vul je huisnummer correct in om het adres compleet te maken.',
  firstName: 'Voornaam is niet correct ingevuld.',
  middleName: 'Tussenvoegsel is niet correct ingevuld.',
  lastName: 'Achternaam is niet correct ingevuld.',
  email: 'E-mailadres is niet correct ingevuld.',
  phoneNumber: 'Telefoonnummer is niet correct ingevuld.',
  postalCode: 'Controleer je postcode. Deze is ongeldig.',
  membershipConsent: 'Let op: het lidmaatschap is verplicht.',
}

const getDateYearsBack = (years: number): Date =>
  new Date(
    new Date().getFullYear() - years,
    new Date().getUTCMonth(),
    new Date().getDate(),
  )

const dateMin = getDateYearsBack(120)
const dateMax = computed(() => getDateYearsBack(minAge.value))

const isUnderage = computed(
  () => data.birthDate && new Date(data.birthDate) >= getDateYearsBack(18),
)

const getMembershipName = () =>
  props.saleItem.theme?.communityName === 'Beam' ? 'BEAM' : 'EO'

const isMember = ref<boolean>(false)
const isMobile = useMediaQuery('(max-width: 768px)')

onMounted(() => {
  const postalCodeNode = getNode('postalCode')
  const houseNumberNode = getNode('houseNumber')
  if (postalCodeNode && houseNumberNode) {
    houseNumberNode.on('commit', async ({ payload }) => {
      const { data } = await useFetch(
        `/api/getAddress?postalcode=${postalCodeNode.value}&housenumber=${payload}`,
      )
      if (data.value) {
        const addressInfo = data.value.data as Address
        getNode('streetName')?.input(addressInfo.streetName)
        getNode('cityName')?.input(addressInfo.city)
      }
    })
  }
  const emailNode = getNode('email')
  const dateOfBirthNode = getNode('dateOfBirth')

  if (
    props.membershipRequired &&
    postalCodeNode &&
    houseNumberNode &&
    emailNode &&
    dateOfBirthNode
  ) {
    emailNode.on('commit', async ({ payload }) => {
      const dateOfBirth = `${dateOfBirthNode.value}`
      const { data } = await useFetch(
        `/api/getMember?postalcode=${postalCodeNode.value}&housenumber=${houseNumberNode.value}&email=${payload}&dateofbirth=${dateOfBirth}`,
      )
      if (data.value?.status === 200) {
        isMember.value = true
        emit('membershipChanged', !isMember.value)
      } else {
        isMember.value = false
      }
    })
  }
})

function forgotGender() {
  const genderNode = getNode('gender')
  if (!genderNode?.value) {
    genderNode?.setErrors(['Let op: Het geslacht is (nog) niet ingevuld.'])
  } else {
    genderNode?.clearErrors()
  }
  return true
}

function forgotDateOfBirth() {
  const dateOfBirthNode = getNode('dateOfBirth')
  if (!dateOfBirthNode?.value) {
    dateOfBirthNode?.setErrors([
      'Let op: Je geboortedatum is (nog) niet ingevuld.',
    ])
  } else {
    dateOfBirthNode?.clearErrors()
  }
  return true
}
</script>
<template>
  <div>
    <FormKit
      id="gender"
      :classes="{
        options: 'flex list-none pl-0',
        label: 'px-2 text-lg',
        option: 'px-2',
        wrapper: 'flex items-center',
        messages: 'py-1',
        message: 'text-red-500',
        outer: 'mt-2 mb-4',
        decorator: 'hidden',
      }"
      type="radio"
      name="gender"
      :options="[
        { label: 'Dhr.', value: 'man' },
        { label: 'Mevr.', value: 'vrouw' },
        { label: 'Anders *', value: 'onbekend' },
      ]"
      :validation-messages="{
        required: errorMessages.gender,
      }"
      validation="required"
    />
    <FormKit
      id="firstName"
      outer-class="required"
      type="text"
      label="Voornaam"
      name="firstName"
      placeholder="Voornaam *"
      :validation-messages="{
        required: errorMessages.firstName,
        length: errorMessages.firstName,
        matches: errorMessages.firstName,
      }"
      :validation-rules="{ forgotGender }"
      validation="required|length:2,32|matches:/^[\u00C0-\u017Fa-zA-Z\s'-]*$/|(3000)forgotGender"
    />
    <!-- TODO: Middle names mixin -->
    <div class="grid grid-cols-6 gap-4">
      <div class="col-span-2">
        <FormKit
          inner-class="$reset overflow-hidden rounded-md formkit-invalid:border-red-500 formkit-invalid:border"
          type="text"
          label="Tussenvoegsel"
          name="middleName"
          :validation-messages="{
            length: errorMessages.middleName,
            matches: errorMessages.middleName,
          }"
          placeholder="Tussenvoegsel"
          validation="length:0,12|matches:/^[a-zA-Z\s']*$/"
        />
      </div>
      <div class="col-span-4">
        <FormKit
          outer-class="required"
          type="text"
          label="Achternaam"
          name="lastName"
          :validation-messages="{
            required: errorMessages.lastName,
            length: errorMessages.lastName,
            matches: errorMessages.lastName,
          }"
          placeholder="Achternaam *"
          validation="length:2,32|required|matches:/^[\u00C0-\u017Fa-zA-Z\s'-]*$/"
        />
      </div>
    </div>
    <FormKit
      id="dateOfBirth"
      v-model="data.birthDate"
      name="dateOfBirth"
      outer-class="required relative"
      years-class="px-0 pt-0 gap-1"
      panel-wrapper-class="p-0"
      type="datepicker"
      :picker-only="isMobile"
      label="Geboortedatum"
      value-format="YYYY-MM-DD"
      format="DD-MM-YYYY"
      :validation-messages="{
        invalidDate: errorMessages.dateOfBirthIncorrect,
        required: errorMessages.dateOfBirthRequired,
      }"
      placeholder="Geboortedatum *"
      :sequence="['year', 'month', 'day']"
      :max-date="dateMax"
      :min-date="dateMin"
      validation="required"
    />
    <FormParentConfirm v-if="isBeam && isUnderage" />
    <div class="grid grid-cols-8 gap-4">
      <div class="col-span-3">
        <FormKit
          id="postalCode"
          outer-class="required"
          type="text"
          label="postalCode"
          name="postalCode"
          :validation-messages="{
            required: errorMessages.postalCode,
            isValidPostalCode: errorMessages.postalCode,
          }"
          placeholder="Postcode *"
          validation="required|isValidPostalCode|(3000)forgotDateOfBirth"
          :validation-rules="{ isValidPostalCode, forgotDateOfBirth }"
        />
      </div>
      <div class="col-span-3">
        <FormKit
          id="houseNumber"
          outer-class="required"
          type="number"
          label="Huisnr"
          name="homeNumber"
          :delay="1000"
          :validation-messages="{
            required: errorMessages.homeNumber,
            length: errorMessages.homeNumber,
          }"
          placeholder="Huisnr. *"
          validation="required|length:1,5"
        />
      </div>
      <div class="col-span-2">
        <FormKit
          inner-class="$reset overflow-hidden rounded-md formkit-invalid:border-red-500 formkit-invalid:border"
          type="text"
          label="Toev."
          name="homeNumberAddition"
          :validation-messages="{ length: errorMessages.homeNumberAddition }"
          placeholder="Toev."
          validation="length:1,6"
        />
      </div>
    </div>
    <FormKit
      id="streetName"
      outer-class="required"
      type="text"
      label="Straatnaam"
      name="streetName"
      placeholder="Straat *"
      :validation-messages="{
        required: errorMessages.streetName,
        length: errorMessages.streetName,
        matches: errorMessages.streetName,
      }"
      validation="required|length:2,32|matches:/^[\u00C0-\u017Fa-zA-Z0-9\s.:;'-]*$/"
    />
    <FormKit
      id="cityName"
      outer-class="required"
      type="text"
      label="woonplaats"
      name="cityName"
      placeholder="Woonplaats *"
      :validation-messages="{
        required: errorMessages.cityName,
        matches: errorMessages.cityName,
      }"
      validation="required|length:1,30|matches:/^[\u00C0-\u017Fa-zA-Z0-9\s.:;'-]*$/"
    />
    <FormKit
      outer-class="required"
      type="text"
      label="phoneNumber"
      name="phoneNumber"
      placeholder="Telefoon *"
      :validation-messages="{
        required: errorMessages.phoneNumber,
        length: errorMessages.phoneNumber,
        isValidPhone: errorMessages.phoneNumber,
      }"
      validation="required|length:10,12|isValidPhone"
      :validation-rules="{ isValidPhone }"
    />
    <FormKit
      id="email"
      outer-class="required"
      type="email"
      label="email"
      name="email"
      placeholder="E-mailadres *"
      validation="required|email"
      :delay="1500"
      :validation-messages="{
        required: errorMessages.email,
        email: errorMessages.email,
      }"
    />
    <div
      v-if="!!saleItem.newsletters"
      class="mb-8"
    >
      <FormKit
        v-for="{ text, key } in saleItem.newsletters ?? []"
        :key="key"
        :classes="{
          outer: '!mb-0',
          wrapper: 'flex gap-2 accent-black',
        }"
        type="checkbox"
        :label="text || 'Nieuwsbrief'"
        :name="`newsletter_${key}`"
        validation=""
      />
    </div>
    <slot name="beforeMembership" />
    <div v-if="membershipRequired && !isMember">
      <b>Je bent nog geen {{ getMembershipName() }}-lid!</b>
      <p>
        {{
          saleItem.explanationRequiredMembership
            ? saleItem.explanationRequiredMembership
            : `Dit abonnement/product is speciaal voor EO-leden. Er is bij ons geen lidmaatschap bekend met de door jou
                        ingevulde gegevens hierboven.`
        }}
      </p>
      <FormKit
        :classes="{
          options: 'grid gap-2 list-none pl-0 pt-0',
          label: 'px-2 font-bold',
          option: 'px-2',
          wrapper: 'flex',
          messages: 'py-1',
          message: 'text-red-500',
          outer: 'mt-2 mb-4',
          decorator: 'hidden',
        }"
        type="radio"
        name="claimsIsMember"
        :options="[
          {
            label: `Ja, ik word ${getMembershipName()}-lid`,
            value: false,
            help: !isBeam
              ? `Voor 20,- per jaar ben je lid en steun je de missie van de ${getMembershipName()}.`
              : '',
          },
          {
            label: `Nee, ik ben wél ${getMembershipName()}-lid`,
            value: true,
            help: `We verwerken je aanvraag handmatig. Hierdoor kan je aanvraag iets langer duren dan je van ons gewend bent.`,
          },
        ]"
        :validation-messages="{
          required: errorMessages.gender,
        }"
        validation="required"
        @input="(value) => emit('membershipChanged', !value)"
      />
    </div>
    <slot />
  </div>
</template>
<style>
/* TODO: Used because the nl localization of the ui message invalidDate isn't working out of the box */
#dateOfBirth-rule_invalidDate {
  display: none;
}

/* Custom overwrite as Formkit is not doing what it should be */
/* We should try to fix this later on */
[data-disabled='true'] {
  opacity: 0.5;
  pointer-events: none;
  cursor: pointer;
}

.formkit-week {
  display: grid;
  width: 100% !important;
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

.formkit-weekDays {
  display: grid;
  width: 100% !important;
  grid-template-columns: repeat(7, minmax(0, 1fr));
}
</style>
