<script setup lang="ts">
const { getBankIcon } = useBuckarooUtils()
import GET_BANKS from '@/graphql/getBanks.graphql'
const props = defineProps<{
  organisation: string
}>()

async function loadBanks() {
  const { data } = await useAsyncQuery(`getBanks`, GET_BANKS, {
    organisation: props.organisation,
  })

  return data.value?.banks.map((bank: { bic: string; name: any }) => ({
    asset: getBankIcon(bank.bic),
    label: bank.name,
    value: bank.bic,
  }))
}

loadBanks() // load in advance so the query result is in cache
</script>
<template>
  <FormKit
    label="bankSelect"
    name="bankSelect"
    type="dropdown"
    :options="loadBanks"
    placeholder="Selecteer een bank *"
  >
    <template #option="{ option }">
      <div class="flex items-center">
        <img
          :src="option.asset"
          alt="optionAvatar"
          class="mr-2 max-h-5 w-5"
        />
        <span>
          {{ option.label }}
        </span>
      </div>
    </template>
  </FormKit>
</template>
