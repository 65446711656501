<script lang="ts" setup></script>

<template>
  <div class="mb-5 py-2">
    <FormKit
      name="checkbox"
      :classes="{
        outer: '!mb-0',
        wrapper: 'flex gap-2',
        inner: '!items-start mt-[5px]',
        messages: 'hidden',
        input: `top-[-3px] before:content[''] peer relative !h-[20px] !w-[20px] cursor-pointer appearance-none rounded-md border border-black transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-[15px] before:w-[15px] before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-sm before:transition-opacity checked:border-cyan-400 checked:bg-cyan-400 [&:checked+svg]:opacity-100`,
      }"
      type="checkbox"
      label="Ik geef toestemming om de volgende betalingen automatisch van mijn rekening te laten afschrijven. *"
      validation="accepted"
    >
      <template #decorator>
        <EoIcon
          name="check"
          color="white"
          size="sm"
          class="absolute left-[2px] top-[4px] opacity-0"
        />
      </template>
    </FormKit>
  </div>
</template>
