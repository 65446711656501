<script lang="ts" setup>
const props = defineProps<{
  product: any
  isSelected: boolean
}>()

const classes = computed(() => {
  return [
    'w-100',
    'flex',
    'bg-white',
    'rounded-xl',
    'overflow-hidden',
    'my-4',
    'cursor-pointer',
    'hover:shadow-md',
    'hover:translate-x-4',
    'transition-all',
    props.isSelected && `border border-[var(--secondary)]`,
    props.isSelected ? 'shadow-md' : 'shadow-sm',
  ]
})
</script>
<template>
  <div
    :class="classes"
    color="orange-400"
    @click="$emit('productChoiceSelected', product)"
  >
    <EoImg
      class="aspect-square w-24 rounded-xl bg-gray-100 object-cover"
      :src="product.image[0].cdnFiles[0].url"
    />
    <div class="flex w-full flex-col justify-center p-5">
      <h5 class="text-2xl">{{ product.name }}</h5>
      <p class="text-gray-400">{{ product.readablePrice }}</p>
    </div>
  </div>
</template>
