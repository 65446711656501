<script lang="ts" setup>
import type { Product, SingleSale, Subscription } from '@eo/graphql-types'

defineProps<{
  products: Product
  buttonColor: string
  saleItem: Subscription | SingleSale
}>()
const emit = defineEmits(['productSelected', 'continueForm'])

const selectedProduct = ref<Product | null>(null)

const handleSelect = (product: Product) => {
  selectedProduct.value = product
  emit('productSelected', product)
}
</script>
<template>
  <div>
    <div>
      <h2>Maak je keuze</h2>
      <p>Selecteer één van de volgende opties</p>
      <div>
        <ProductChoiceSelectionItem
          v-for="product in products"
          :key="product.id"
          :is-selected="
            !!(selectedProduct && selectedProduct.name === product?.name)
          "
          :product="product"
          @product-choice-selected="handleSelect"
        />
      </div>
    </div>
    <div class="mb-8 lg:hidden">
      <PaymentBasket
        :base-price="saleItem.basePrice"
        :allow-multiple="saleItem.allowMultiple || false"
        :button-color="buttonColor"
      />
    </div>

    <EoButton
      :class="{ 'w-full': true, 'text-black': !selectedProduct }"
      :disabled="!selectedProduct"
      label="Volgende stap"
      :color="buttonColor"
      @click="selectedProduct ? $emit('continueForm') : ''"
    >
      Volgende stap
      <EoIcon
        class="ml-1"
        name="arrow-right"
        size="lg"
      />
    </EoButton>
  </div>
</template>
