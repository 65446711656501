<script lang="ts" setup>
import type { SingleSale, Subscription, Text } from '@eo/graphql-types'

const props = defineProps<{
  saleItem: Subscription | SingleSale
}>()

const variablesColor = computed(() => {
  const backgroundColor = useColor(props.saleItem.theme?.background || '')
  const primary = useColor(props.saleItem.theme?.primary || '')
  const secondary = useColor(props.saleItem.theme?.secondary || '')

  return {
    '--backgroundColor': backgroundColor.getHex(),
    '--primary': primary.getHex(),
    '--secondary': secondary.getHex(),
  }
})
</script>
<template>
  <div :style="variablesColor">
    <div class="h-1 w-full bg-[var(--secondary)]" />
    <div class="grid min-h-[100vh] grid-cols-1 lg:grid-cols-[600px_auto]">
      <FormProductInfo
        v-if="saleItem"
        :hero="saleItem.hero[0].cdnFiles[0]?.url || ''"
        :intro-content="(saleItem.intro as Text[])"
        :title="saleItem.title || ''"
        :base-price="saleItem.basePrice || 0.0"
        :allow-multiple="saleItem.allowMultiple || false"
        :button-color="saleItem.theme?.button ?? 'purple-700'"
      />
      <div class="bg-[var(--backgroundColor)]">
        <div class="m-auto w-full max-w-md px-4 py-8 md:px-0 md:py-20">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>
