import type { PersonFormData } from '~/types/personFormData'

export default function useNewsletterUtils() {
  const subscribeToNewsletters = async (formData: PersonFormData) => {
    const newsletters = (Object.keys(formData) as (keyof PersonFormData)[])
      .filter((key) => key.startsWith('newsletter_') && !!formData[key])
      .map((key) => key.slice('newsletter_'.length))
    const bodies = newsletters.map((key) => ({
      campaignId: key,
      email: formData.email,
    }))
    await Promise.allSettled(
      bodies.map((body) =>
        useFetch('/api/newsletter', {
          method: 'POST',
          body,
        }),
      ),
    )
  }

  return {
    subscribeToNewsletters,
  }
}
