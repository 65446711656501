<script setup lang="ts">
import { useOrderStore } from '@/stores/orderStore'
import AcceptMandateCheckbox from './AcceptMandateCheckbox.vue'
const props = withDefaults(
  defineProps<{
    iDealEnabled: boolean
    incassoEnabled: boolean
    buttonColor: string
  }>(),
  {
    incassoEnabled: true,
  },
)
const orderStore = useOrderStore()
const selectedMethod = ref(props.iDealEnabled ? 'ideal' : 'incasso')
const errorMessages = {
  bankSelect: 'Bank is niet geselecteerd',
  bankNumber: 'Rekeningnummer is niet correct ingevuld',
}

watch(selectedMethod, async (newSelected) => {
  orderStore.setPaymentMethod(newSelected)
})

onMounted(() =>
  props.iDealEnabled
    ? orderStore.setPaymentMethod('ideal')
    : orderStore.setPaymentMethod('incasso'),
)

const { isValidIban } = useValidationUtils()
const { isOneTimePayment } = usePaymentUtils()
</script>
<template>
  <div v-if="props.iDealEnabled && props.incassoEnabled">
    <h4 class="mb-5">Betaalmethode</h4>
    <div class="mb-5">
      <div class="flex gap-3">
        <EoButton
          :color="selectedMethod === 'ideal' ? buttonColor : 'gray-400'"
          outlined
          type="button"
          @click="
            () => {
              selectedMethod = 'ideal'
            }
          "
          label="selectIDealPaymentMethod"
          >iDeal</EoButton
        >
        <EoButton
          :color="selectedMethod === 'incasso' ? buttonColor : 'gray-400'"
          outlined
          type="button"
          @click="
            () => {
              selectedMethod = 'incasso'
            }
          "
          label="selectIncassoPaymentMethod"
          >Incasso</EoButton
        >
      </div>
    </div>
  </div>
  <div>
    <div v-if="selectedMethod === 'ideal'">
      <BankSelectDropdown
        organisation="EO"
        validation="required"
        :validation-messages="{
          required: errorMessages.bankSelect,
        }"
      />
      <AcceptMandateCheckbox v-if="!isOneTimePayment()" />
    </div>
    <FormKit
      v-if="selectedMethod === 'incasso'"
      outer-class="required"
      type="text"
      label="bankNumber"
      name="bankNumber"
      placeholder="Rekeningnummer *"
      validation="required|isValidIban"
      :validation-rules="{ isValidIban }"
      :validation-messages="{
        required: errorMessages.bankNumber,
        isValidIban: errorMessages.bankNumber,
      }"
    />
  </div>
</template>
